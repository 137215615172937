(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

/*
 * Bones Scripts File
 * Author: Eddie Machado
 *
 * This file should contain any js scripts you want to add to the site.
 * Instead of calling it in the header or throwing it inside wp_head()
 * this file will be called automatically in the footer so as not to
 * slow the page load.
 *
 * There are a lot of example functions and tools in here. If you don't
 * need any of it, just remove it. They are meant to be helpers and are
 * not required. It's your world baby, you can do whatever you want.
*/

/*
 * Get Viewport Dimensions
 * returns object with viewport dimensions to match css in width and height properties
 * ( source: http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript )
*/
function updateViewportDimensions() {
  var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
  return { width: x, height: y };
}
// setting the viewport width
var viewport = updateViewportDimensions();

//utility for determining touch devices
function isTouchDevice() {
  return 'ontouchstart' in window // works on most browsers 
  || window.navigator.msMaxTouchPoints > 0; // works on ie10
}

/*
 * Throttle Resize-triggered Events
 * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
 * ( source: http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed )
*/
var waitForFinalEvent = function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout(timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
}();

// how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
var timeToWaitForLast = 100;

/*
 * Here's an example so you can see how we're using the above function
 *
 * This is commented out so it won't work, but you can copy it and
 * remove the comments.
 *
 *
 *
 * If we want to only do it on a certain page, we can setup checks so we do it
 * as efficient as possible.
 *
 * if( typeof is_home === "undefined" ) var is_home = $('body').hasClass('home');
 *
 * This once checks to see if you're on the home page based on the body class
 * We can then use that check to perform actions on the home page only
 *
 * When the window is resized, we perform this function
 * $(window).resize(function () {
 *
 *    // if we're on the home page, we wait the set amount (in function above) then fire the function
 *    if( is_home ) { waitForFinalEvent( function() {
 *
 *	// update the viewport, in case the window size has changed
 *	viewport = updateViewportDimensions();
 *
 *      // if we're above or equal to 768 fire this off
 *      if( viewport.width >= 768 ) {
 *        console.log('On home page and window sized to 768 width or more.');
 *      } else {
 *        // otherwise, let's do this instead
 *        console.log('Not on home page, or window sized to less than 768.');
 *      }
 *
 *    }, timeToWaitForLast, "your-function-identifier-string"); }
 * });
 *
 * Pretty cool huh? You can create functions like this to conditionally load
 * content and other stuff dependent on the viewport.
 * Remember that mobile devices and javascript aren't the best of friends.
 * Keep it light and always make sure the larger viewports are doing the heavy lifting.
 *
*/

/*
 * We're going to swap out the gravatars.
 * In the functions.php file, you can see we're not loading the gravatar
 * images on mobile to save bandwidth. Once we hit an acceptable viewport
 * then we can swap out those images since they are located in a data attribute.
*/
function loadGravatars() {
  // set the viewport using the function above
  viewport = updateViewportDimensions();
  // if the viewport is tablet or larger, we load in the gravatars
  if (viewport.width >= 768) {
    jQuery('.comment img[data-gravatar]').each(function () {
      jQuery(this).attr('src', jQuery(this).attr('data-gravatar'));
    });
  }
} // end function


function parallax() {
  var scrolled = jQuery(window).scrollTop();
  var topOffset = 0;
  var set = 0;
  jQuery('.parallax-bg').each(function (e) {
    topOffset = jQuery(this).offset().top;
    set = -((scrolled - topOffset) * 0.4);
    if (jQuery(this).closest('.bxslider').length > 0) {
      set = -set;
    }
    jQuery(this).css('background-position', '0px ' + set + 'px');
  });
}
jQuery(window).scroll(function (e) {
  parallax();
});

/*
 * Put all your regular jQuery in here.
*/
jQuery(document).ready(function ($) {

  /*
     * Let's fire off the gravatar function
     * You can remove this if you don't need it
  */
  loadGravatars();

  /*
    *  BXSLIDER LOAD
    *  Reference this page for all options.
    *  http://bxslider.com/options
  */

  $('.bxslider').bxSlider({
    //GENERAL OPTIONS
    mode: 'horizontal', //options: 'horizontal', 'vertical', 'fade'
    speed: 500, //options: integer
    slideMargin: 0, //options: integer
    startSlide: 0, //options: integer
    randomStart: false, //options: boolean (true / false)
    slideSelector: '', //options: jQuery selector
    infiniteLoop: true, //options: boolean (true / false)              
    hideControlOnEnd: false, //options: boolean (true / false)
    easing: null, //options: if using CSS: 'linear', 'ease', 'ease-in', 'ease-out', 'ease-in-out', 'cubic-bezier(n,n,n,n)'. If not using CSS: 'swing', 'linear' (see the above file for more options)
    captions: false, //options: boolean (true / false)
    ticker: false, //options: boolean (true / false)
    tickerHover: false, //options: boolean (true / false)
    adaptiveHeight: false, //options: boolean (true / false)
    adaptiveHeightSpeed: 500, //options: integer
    video: false, //options: boolean (true / false)
    responsive: true, //options: boolean (true / false)
    useCSS: true, //options: boolean (true / false)
    preloadImages: 'visible', //options: 'all', 'visible'
    //TOUCH OPTIONS
    touchEnabled: true, //options: boolean (true / false)
    swipeThreshold: 50, //options: integer
    oneToOneTouch: true, //options: boolean (true / false)
    preventDefaultSwipeX: true, //options: boolean (true / false)
    preventDefaultSwipeY: false, //options: boolean (true / false)
    //PAGER OPTIONS
    pager: false, //options: boolean (true / false)
    pagerType: 'full', //options: 'full', 'short'
    pagerShortSeparator: ' / ', //options: string
    pagerSelector: null, //options: jQuery selector
    pagerCustom: null, //options: jQuery selector
    buildPager: null, //options: function(slideIndex)
    //CONTROLS OPTIONS
    controls: true, //options: boolean (true / false)
    nextText: '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>', //options: string
    prevText: '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>', //options: string
    nextSelector: null, //options: jQuery selector
    prevSelector: null, //options: jQuery selector
    autoControls: false, //options: boolean (true / false)
    startText: 'Start', //options: string
    stopText: 'Stop', //options: string
    autoControlsCombine: false, //options: boolean (true / false)
    autoControlsSelector: null, //options: jQuery selector
    //AUTO OPTIONS
    auto: true, //options: boolean (true / false)
    pause: 4000, //options: integer
    autoStart: true, //options: boolean (true / false)
    autoDirection: 'next', //options: 'next', 'prev'
    autoHover: false, //options: boolean (true / false)
    autoDelay: 0, //options: integer
    //CAROUSEL OPTIONS
    minSlides: 1, //options: integer
    maxSlides: 1, //options: integer
    moveSlides: 0, //options: integer
    slideWidth: 0, //options: integer
    //CALLBACKS OPTIONS
    onSliderLoad: function onSliderLoad() {}, //options: function(currentIndex){ // your code here }
    //arguments:
    //currentIndex: element index of the current slide

    onSlideBefore: function onSlideBefore() {}, //function($slideElement, oldIndex, newIndex){ // your code here }
    //arguments:
    //$slideElement: jQuery element of the destination element
    //oldIndex: element index of the previous slide (before the transition)
    //newIndex: element index of the destination slide (after the transition)
    onSlideAfter: function onSlideAfter() {}, //options: function($slideElement, oldIndex, newIndex){ // your code here }
    //arguments:
    //$slideElement: jQuery element of the destination element
    //oldIndex: element index of the previous slide (before the transition)
    //newIndex: element index of the destination slide (after the transition)
    onSlideNext: function onSlideNext() {}, //options: function($slideElement, oldIndex, newIndex){ // your code here }
    //arguments:
    //$slideElement: jQuery element of the destination element
    //oldIndex: element index of the previous slide (before the transition)
    //newIndex: element index of the destination slide (after the transition)
    onSlidePrev: function onSlidePrev() {} //options: function($slideElement, oldIndex, newIndex){ // your code here }
    //arguments:
    //$slideElement: jQuery element of the destination element
    //oldIndex: element index of the previous slide (before the transition)
    //newIndex: element index of the destination slide (after the transition)
  });

  if (!isTouchDevice()) {
    $('.add-active').hover(function () {
      $(this).toggleClass('active');
    });
  }

  $('.builder-hero').css('opacity', '1');

  //masonry
  var $container = $('.builder-blog-posts .blog-post-container');
  $container.imagesLoaded(function () {
    $container.masonry({
      itemSelector: '.masonry-layout'
    });
  });

  //toggle mobile menu
  $('.nav-toggle').click(function () {
    $('.mobile-menu').toggleClass('open');
  });

  //disable mobile clicking of menu items with children
  $('.mobile-menu').on('click', 'ul.menu > li.menu-item-has-children:not(.open) > a', function (e) {
    e.preventDefault();
    $('.mobile-menu li.menu-item-has-children').removeClass('open');
    $('.mobile-menu li.menu-item-has-children ul').removeClass('open');
    $(this).parent().addClass('open');
    $(this).parent().find('ul').addClass('open');
  });

  $('.mobile-menu').on('click', 'ul.menu > li.menu-item-has-children > span', function (e) {
    $('.mobile-menu li.menu-item-has-children').removeClass('open');
    $('.mobile-menu li.menu-item-has-children ul').removeClass('open');
  });
}); /* end of as page load scripts */

jQuery(function ($) {

  $(document).ready(function () {
    blockquoteLength();
    postReadTime();
    formFieldDetection();
    twoColTextMediaEqualHeight();
    slickImageGallery();
    // imageGalleryModal()
    // slickRelatedPosts()
    if ($(window).width() > 767) {
      connectSidebar();
    } else {
      $('.m-sidebar-mobile .m-connect-sidebar').addClass('m-connect-sidebar-mobile');
    }

    $('.m-twocol-text-media-media-images-carousel.slick-me:not(.slick-initialized)').slick({
      'slidesToShow': 1,
      'slidesToScroll': 1,
      'mobileFirst': true,
      'autoplay': true,
      'autoplaySpeed': 2000,
      'dots': true,
      'arrows': false
    });
  });

  $(window).on('resize orientationchange', function () {
    twoColTextMediaEqualHeight();
    slickImageGallery();
    accordionImageHeight();
    if ($(window).width() > 767) {
      connectSidebar();
    } else {
      $('.m-sidebar-mobile .m-connect-sidebar').addClass('m-connect-sidebar-mobile');
    }
  });

  $(window).on('load', function () {
    formFieldDetection();
    accordionImageHeight();
  });

  $(document).on('gform_post_render', function (event, form_id, current_page) {
    formFieldDetection();
  });

  $(window).scroll(function () {
    var curPos = $(this).scrollTop(),
        ft = $('footer').offset().top,
        wh = $(window).height(),
        stop = ft - wh + 37;

    if (curPos > 300) {
      $('.back-to-top').fadeIn(200);
      if (curPos >= stop) {
        $('.back-to-top').addClass('stick');
      } else {
        $('.back-to-top').removeClass('stick');
      }
    } else {
      $('.back-to-top').fadeOut(200);
    }
  });

  $('.back-to-top').on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 300);
  });

  function blockquoteLength() {
    $('blockquote').each(function () {
      var text = $(this).text();

      if (text.length > 150 && text.length <= 300) {
        $(this).addClass('bq-med');
      }
      if (text.length > 300) {
        $(this).addClass('bq-large');
      }

      if ($(this).find('cite').length > 0) {
        $(this).addClass('with-cite');
      }
    });
  }

  function postReadTime() {
    if ($('.t-single').length > 0) {
      var text = $('.t-single-content').text(),
          wordCount = text.replace(/[^\w ]/g, "").split(/\s+/).length;

      var timeInMinutes = Math.floor(wordCount / 228) + 2;
      var timeAsString = timeInMinutes + ' min.';

      $('.t-single .read-time').html(timeAsString);
    }
  }

  function imageGalleryDoubleSize() {
    $('.m-image-gallery-double.double-portrait, .m-image-gallery-triple.double-portrait').each(function () {
      var images = $(this).find('.m-image-gallery-img');
      var biggestImg = images[0];
      $(images).each(function () {
        if ($(this).height() > $(biggestImg).height()) {
          biggestImg = $(this);
        }
      });
      biggestH = $(biggestImg).height();
      $(this).find('.m-image-gallery-img').height(biggestH);
    });
  }

  function slickImageGallery() {
    $('.m-image-gallery-carousel:not(.slick-initialized)').slick({
      'slidesToShow': 1,
      'slidesToScroll': 1,
      'mobileFirst': true,
      'arrows': true,
      responsive: [{
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidestoScroll: 2
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidestoScroll: 3
        }
      }]
    });
    if ($(window).width() < 640) {
      var biggestH;
      $.when(imageGalleryDoubleSize()).done(function () {
        $('.m-image-gallery-double:not(.slick-initialized)').slick({
          'slidesToShow': 1,
          'slidesToScroll': 1,
          'mobileFirst': true,
          'arrows': true
        });
        $('.m-image-gallery-double').each(function () {
          var imgH = $(this).find('.m-image-gallery-img img').height();
          var arrowTop = imgH / 2;
          $(this).find('.slick-arrow').css('top', arrowTop);
        });
      });
    } else {
      $('.m-image-gallery-double.slick-initialized').slick('unslick');
      imageGalleryDoubleSize();
    }
    if ($(window).width() < 992) {
      var biggestH;
      $.when(imageGalleryDoubleSize()).done(function () {
        $('.m-image-gallery-triple:not(.slick-initialized)').slick({
          'slidesToShow': 1,
          'slidesToScroll': 1,
          'mobileFirst': true,
          'arrows': true,
          'responsive': [{
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidestoScroll: 1
            }
          }]
        });
        $('.m-image-gallery-triple').each(function () {
          var imgH = $(this).find('.m-image-gallery-img img').height();
          var arrowTop = imgH / 2;
          $(this).find('.slick-arrow').css('top', arrowTop);
        });
      });
    } else {
      $('.m-image-gallery-triple.slick-initialized').slick('unslick');
      imageGalleryDoubleSize();
    }
  }

  $('img[data-toggle=modal]').on('click', function () {
    var modalId = $(this).parents('.m-image-gallery').data('modal-id');
    var activeImg = $(this).data('img-count');
    $('#gallery-' + modalId).on('shown.bs.modal', function () {
      $('#gallery-' + modalId + ' .slider-for:not(.slick-initialized)').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '#gallery-' + modalId + ' .slider-nav'
      });
      $('#gallery-' + modalId + ' .slider-nav:not(.slick-initialized)').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '#gallery-' + modalId + ' .slider-for',
        arrows: true,
        focusOnSelect: true,
        infinite: false,
        mobileFirst: true,
        responsive: [{
          breakpoint: 640,
          settings: {
            slidesToShow: 5
          }
        }]
      });
      $('#gallery-' + modalId + ' .slider-nav .modal-gallery-img-thumb-wrapper.slick-current').removeClass('slick-current');
      $('#gallery-' + modalId + ' .slider-nav .modal-gallery-img-thumb-wrapper[data-slick-index=' + activeImg + ']').addClass('slick-current');
      $('#gallery-' + modalId + ' .modal-body').removeClass('pre-render');
    });
    $('#gallery-' + modalId).on('hide.bs.modal', function () {
      $('#gallery-' + modalId + ' .slider-for.slick-initialized').slick('unslick');
      $('#gallery-' + modalId + ' .slider-nav.slick-initialized').slick('unslick');
      $('#gallery-' + modalId + ' .modal-body').addClass('pre-render');
    });
  });

  // accordion with lazy image
  function accordionImageHeight() {
    $('.m-accordion').each(function () {
      var id = $(this).find('.accordion').attr('id');
      var card = $(this).find('.accordion .card');
      $('#' + id).on('show.bs.collapse', function (e) {
        if ($(e.target).find('img').length > 0) {
          var $img = $(e.target).find('img');
          var imgH = $img.attr('height');
          var bodyH = parseInt(imgH) + 60;
          $(e.target).find('.card-body').css('min-height', bodyH + 'px');
        }
      });
    });
  }

  // FORM FUNCTIONS 
  var inputs = '.gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):visible, .gform_wrapper textarea';

  $('textarea').each(function () {
    var offset = this.offsetHeight - this.clientHeight;

    var resizeArea = function resizeArea(el) {
      $(el).css('height', '50px').css('height', el.scrollHeight + offset);
    };

    $(this).on('keyup input', function () {
      resizeArea(this);
    });
  });

  function formFieldDetection() {
    $('.gform_wrapper .gfield').each(function () {
      if ($(this).find('.gfield_required').length > 0) {
        $(this).addClass('required');
      }
    });
    $(inputs).each(function () {
      if ($(this).val() !== '') {
        $(this).parents('.gfield').find('label:first').addClass('in-focus');
      }
    });
    $('.gform_wrapper .ginput_container_select').siblings('label').addClass('in-focus');
  }

  $(document).on('focus', inputs, function () {
    $(this).parents('.gfield').find('label:first').addClass('in-focus gray');
  });

  $(document).on('blur', inputs, function () {
    $(this).parents('.gfield').find('label:first').addClass('in-focus gray');

    if ($(this).val().length > 0) {
      $(this).parents('.gfield').find('label:first').addClass('in-focus').removeClass('gray');
    } else {
      $(this).parents('.gfield').find('label:first').removeClass('in-focus').removeClass('gray');
    }
  });

  function connectSidebar() {
    if ($('.m-connect-sidebar').length > 0) {
      $(window).on('load', function () {
        var contentHeight = $('.content-wrapper-with-sidebar').height();

        var sidebarHeight = $('.m-sidebar').height();

        if ($('body').hasClass('give-success')) {
          contentHeight = sidebarHeight + 200;
        }

        if (sidebarHeight >= contentHeight) {
          $('.content-wrapper-with-sidebar').height(sidebarHeight + 100);
        } else {
          sidebarHeight = contentHeight - 100;
          $('.m-sidebar').height(sidebarHeight);
        }
      });

      var stickySidebar = $('.m-connect-sidebar:not(m-connect-sidebar-mobile)').offset().top;
      stickySidebar = stickySidebar - 90;
      var sidebarWidth = $('.m-connect-sidebar:not(m-connect-sidebar-mobile)').width();
      var sideMenuWidth = $('.page-menu').width();

      $(window).scroll(function () {
        if ($(window).scrollTop() > stickySidebar) {
          $('.m-connect-sidebar:not(m-connect-sidebar-mobile)').addClass('stick-me').css('max-width', sideMenuWidth);
        } else {
          $('.m-connect-sidebar:not(m-connect-sidebar-mobile)').removeClass('stick-me').css('max-width', sideMenuWidth);
        }
      });
    }

    // else {
    //   $('.m-connect-sidebar').removeClass('stick-me').width('auto')
    // }
  }

  function twoColTextMediaEqualHeight() {
    $('.m-twocol-text-media').each(function () {
      var rowH = $(this).find('.m-twocol-text-media-text').height();
      var mediaH = $(this).find('.m-twocol-text-media-media').height();

      if (window.innerWidth > 991) {
        $(this).find('.m-twocol-text-media-media').removeClass('stacked-size');
        if (mediaH < rowH || mediaH > rowH + 30 && rowH > 300) {
          var newH = rowH + 30;
          $(this).find('.m-twocol-text-media-media').height(newH).removeClass('stacked-size');
          $(this).find('img').css('max-height', newH).removeClass('pre-render');
        }
      } else {
        $(this).find('.m-twocol-text-media-media').addClass('stacked-size');
      }
    });
  }

  $('.m-twocol-text-media').each(function () {
    var videoId = $(this).find('.video-modal').data('video-id');

    $('[data-target=#videoModal-' + videoId + ']').on('click', function () {
      var videoSrc = 'https://player.vimeo.com/video/' + videoId;
      var videoSrcAuto = videoSrc + '?autoplay=1&color=9d162e';
      $('#videoModal-' + videoId).on('shown.bs.modal', function () {
        $(this).find('iframe').attr('src', videoSrcAuto);
      });
      $('#videoModal-' + videoId).on('hide.bs.modal', function () {
        $(this).find('iframe').attr('src', null);
      });
    });
  });

  function slickThis() {
    $('.m-testimonials').each(function () {
      if (!$(this).hasClass('no-slick')) {
        $('.m-testimonials-carousel.slick-initialized').each(function () {
          $(this).slick('unslick');
        });

        $('.m-testimonials-carousel:not(.slick-initialized)').each(function () {
          $(this).slick({
            slidesToShow: 1,
            arrows: false,
            dots: true
          });
        });
      }
    });
  }
});

},{}]},{},[1]);
